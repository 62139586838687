module.exports = {
  colors: {
    // "Primary-900": "#202495",
    'Primary-900': '#4435AC',
    'Primary-800': '#382fa4',
    'Primary-700': '#4535ac',
    'Primary-600': '#533db5',
    'Primary-500': '#5c42bb',
    'Primary-400': '#765dc6',
    'Primary-300': '#8f79d0',
    'Primary-200': '#afa0dd',
    'Primary-100': '#cfc6ea',
    'Primary-50': '#ece8f6',
    'Secondary-300': '#FF0052',
    'Secondary-200': '#ff8ea8',
    'Secondary-100': '#ffbbcb',
    'Secondary-50': '#ffe4ea',
    SP2: '#FFBBCB',
    // SP2: "#f4a34b",
    SP1: '#ffe600',
    // SP3: "#3dcfff",
    SP3: '#FF0052',
    SP4: '#db3532',
    SP5: '#fd4839',
    // SP6: "#8549eb",
    SP6: '#8549EB',
    // SP7: "#f2eff6",
    SP7: '#F6F6F6',
    // SP8: "#00A9F3"
    SP8: '#EE0050',
    Body: '#3d3d3d',
    'Body-Inactive': '#818181',
    'Body-Disabled': '#c9c9c9',
    White: '#ffffff',
    Black: '#000000',
    Green: '#25D816',
    Highlight: '#ffe500',
    Transparent: 'rgba(255, 255, 255, 0)',
    Opacity9: 'rgba(0, 0, 0, 0.9)',
    // Activity Category Colours
    'Splash-Zone': '#49C9F5',
    'Power-Teams': '#F15846',
    'Super-Sports': '#7C62AB',
    'Globe-Trotters': '#87C870',
    'Creative-Inventors': '#F1696C',
    'Brain-Boosters': '#F99F1B',
    'Zen-Masters': '#FAE80B',
    // Tear Colours
    'Dark-Theme-Tear-Primary': '#ff5e67',
    'Dark-Theme-Tear-Secondary': '#202495',
    'Light-Theme-Tear-Primary': '#ffe600',
    'Light-Theme-Tear-Secondary': '#8549eb',
    'Alternative-Theme-Tear-Primary': '#202495',
    'Alternative-Theme-Tear-Secondary': '#ff5e86',
    'White-Theme-Tear-Primary': '#ff5e86',
    'White-Theme-Tear-Secondary': '#202495',
    // Hero Gradients
    'Homepage-Hero/Gradient-Start': '#4435AC',
    'Homepage-Hero/Gradient-End': 'transparent',
    'Secondary-Hero/Gradient-Start': '#202495',
    'Secondary-Hero/Gradient-End': '#5c42bb',
    // Background Texture Colours
    'Background-Texture-Primary': '#DAC8FA',
    'Primary-Button-Text': '#fff',
  },
  fontSize: {
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },
  fontFamily: { primary: 'Raleway', secondary: 'Raleway' },
  boxShadow: {
    Inner: 'inset 0px 2px 4px 0px rgba(0,34,66,0.2)',
    Sm: '0px 1px 2px 0px rgba(0,34,66,0.05)',
    Default: '0px 1px 2px 0px rgba(0,34,66,0.06), 0px 1px 3px 0px rgba(0,34,66,0.1)',
    Md: '0px 2px 4px -1px rgba(0,34,66,0.06), 0px 4px 6px -1px rgba(0,34,66,0.1)',
    Lg: '0px 4px 6px -2px rgba(0,34,66,0.05), 0px 10px 15px -3px rgba(0,34,66,0.1)',
    Xl: '0px 10px 10px -5px rgba(0,34,66,0.04), 0px 20px 25px -5px rgba(0,34,66,0.1)',
    '2xl': '0px 25px 50px -12px rgba(0,0,0,0.3)',
  },
  borderRadius: {
    none: '0',
    xs: '0.125rem',
    sm: '0.13320313394069672rem',
    default: '0.18736979365348816rem',
    lg: '0.1875rem',
    xl: '0.25rem',
    '2xl': '0.3125rem',
    '3xl': '0.3747395873069763rem',
    '4xl': '0.375rem',
    '5xl': '0.5rem',
    '6xl': '0.625rem',
    '7xl': '0.6660156846046448rem',
    '8xl': '0.7305389046669006rem',
    full: '9999px',
  },
  screens: {
    sm: '672px' /* 640 ith 16px gutter */,
    md: '800px' /* 768 with 16px gutter */,
    lg: '1056px' /* 1024 with 16px gutter */,
    xl: '1440px' /* 1280 with 80px gutter */,
    '2xl': '1600px' /*1440 with 80px gutter */,
    '3xl': '1920px' /* 1536 with 192px gutter */,
  },
  container: {
    padding: {
      DEFAULT: '16px',
      xl: '80px',
      '2xl': '192px',
    },
  },
  extend: {
    transitionDelay: {
      400: '400ms',
      600: '600ms',
      800: '800ms',
      900: '900ms',
      1100: '1100ms',
      1200: '1200ms',
      1300: '1300ms',
    },
  },
  variants: {
    fill: ['group-hover', 'hover', 'focus'],
    textColor: ['responsive', 'hover', 'focus', 'group-hover'],
    scrollbar: ['rounded'],
    boxShadow: ['responsive', 'hover', 'focus'],
    extend: {
      gridColumnEnd: ['last'],
    },
  },
  safeList: [
    'text-White',
    'text-Highlight',
    'current',
    'fill-current',
    'rotate-180',
    'group-hover:rotate-180',
    'visible',
    'invisible',
    {
      pattern: /text-(Primary|Secondary)-(50|100|200|300|400|500|600|700|800|900)/,
      variants: ['hover', 'group-hover'],
    },
    {
      pattern: /fill-(Primary|Secondary)-(50|100|200|300|400|500|600|700|800|900)/,
      variants: ['hover', 'group-hover'],
    },
  ],
};
